<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Recepcion</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle Orden de Recepcion</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h3 style="border-bottom: 1px solid #005f91">
        ORDEN DE RECEPCION #{{ orden_compra.id }}
      </h3>
    </div>
    <div class="grid style_card">
      <div class="col-4">
        <p class="mb-0">
          <strong>PROVEEDOR: </strong>
          <span>{{ orden_compra.nombre_proveedor }}</span>
        </p>
        <p class="mb-0">
          <strong>ESTADO DE LA ORDEN: </strong>
          <span>{{ orden_compra.estado_texto }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="mb-0">
          <strong>EMPRESA: </strong> <span>{{ sucursales.nombre }}</span>
        </p>
        <p class="mb-0">
          <strong>DIRECCI&Oacute;N: </strong>
          <span>{{ sucursales.direccion }}</span>
        </p>
        <p class="mb-0">
          <strong>TEL&Eacute;FONO: </strong
          ><span>{{ sucursales.telefono ?? "-" }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="mb-0">
          <strong>FECHA SOLICITUD: </strong>
          <span>{{ orden_compra.fecha_registro }}</span>
        </p>
        <p class="mb-0">
          <strong>Nº RECIBO/FACTURA: </strong>
          <span>{{ oc_recepcion.nro_recibo ?? "N/A" }}</span>
        </p>
        <p class="mb-0">
          <strong>SOLICITADO POR: </strong>
          <span>{{ orden_compra.nombre_user }}</span>
        </p>
      </div>
    </div>
    <div class="grid mt-2">
      <DataTable
        ref="dt_oc"
        :value="productos"
        class="p-datatable-sm col-12"
        stripedRows
        showGridlines
        responsiveLayout="scroll"
      >
        <Column   class="col-5" field="descripcion" header="DESCRIPCIÓN/PRODUCTO" >
          {{ producto.descripcion }}
        </Column>
        <Column  class="text-center col-1" field="unidad_medida_nombre" header="U / MEDIDA">
          {{ producto.unidad_medida_nombre }}
        </Column>
        <Column  class="text-center col-2"  field="pivot.cantidad_unidad" header="CANTIDAD UNIDAD">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad_unidad) }}
          </template>
        </Column>
        <Column class="text-center col-2" field="pivot.cantidad" header="CANTIDAD KG">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
          </template>
        </Column>
        <Column class="text-center col-1" field="pivot.precio" header="COSTO">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
        </Column>
        <Column class="text-center " field="pivot.descuento" header="DESCUENTO" v-if="false">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.pivot.descuento ?? 0)
            }}
          </template>
        </Column>
        <Column  class="text-center col-2" header="TOTAL" >
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.cantidad * slotProps.data.pivot.precio
              )
            }}
          </template>
        </Column>
      </DataTable>
    </div>
    <table
      class="table table-bordered table-striped"
      width="100%"
      cellspacing="0"
      style="font-size: 1.3rem"
    >
      <tbody class="text-right">
        <tr>
          <td class="text-left">
            <strong>OBSERVACIONES: </strong>
            {{ oc_recepcion.observacion }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <strong>TOTAL GENERAL: </strong>
            {{ convertirNumeroGermanicFormat(total_general) }}
          </td>
        </tr>
       
       
      </tbody>
    </table>
    <div class="flex col-12 justify-content-end">
      <Button @click="goBack" class="mr-2">
        <i class="pi pi-arrow-circle-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>
      <Button
        label="IMPRIMIR"
        icon="pi pi-file-pdf"
        class="p-button-danger p-button-lg"
        @click="imprimirOC(orden_compra.id)"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import OrdenCompraService from "@/service/OrdenCompraService";
import SucursalService from "@/service/SucursalService";

export default {
  data() {
    return {
      orden_compra: {},
      oc_recepcion: {},
      productos: [],
      sucursales: [],
      idOrdencompra: this.$route.params.id,
      texto_estado: "",
      total_venta: 0,
      imprimiendo: false,
    };
  },
  ordenCompraService: null,
  sucursalService: null,
  created() {
    this.ordenCompraService = new OrdenCompraService();
    this.sucursalService = new SucursalService();
  },
  mounted() {
    this.obtenerSucursales();
    this.obtenerOndenCompras();
  },
  computed: {
    total_general() {
      let total = 0;
      this.productos.forEach((producto) => {
        total +=
          parseFloat(producto.pivot.cantidad * producto.pivot.precio) +
          parseFloat(producto.pivot.descuento ?? 0);
      });
      return total;
    },
  },
  methods: {
    imprimirOC(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.ordenCompraService
        .imprimirOC(id)
        .then(() => {
          this.imprimiendo = false;
        })
        .catch((error) => {
          console.log(error);
          this.imprimiendo = false;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    obtenerOndenCompras() {
      this.ordenCompraService
        .obtenerOrdenCompra(this.idOrdencompra)
        .then((data) => {
          this.orden_compra = data.oc ? data.oc : {};
          this.productos = data.productos ? data.productos : [];
          this.oc_recepcion = data.oc_recepcion ? data.oc_recepcion : {};
          this.productos.forEach((producto) => {
            this.producto = producto;
          });
        });
    },
    obtenerSucursales() {
      this.sucursalService.getSucursalesAll().then((data) => {
        this.sucursales = data;
        this.sucursales.forEach((sucursal) => {
          this.sucursales = sucursal;
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    productos() {
      this.total_venta = 0;
      this.productos.forEach((producto) => {
        this.total_venta += producto.pivot.cantidad * producto.pivot.precio;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
</style>
